import {
  EOpenVideoOnYoutube,
  MultiPlayer,
  toImageProps,
  toLinkProps,
} from '@front/shared/ds';
import { Section, VideoCollection } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

export type TMultiPlayerRowConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'multi-player-row' }
>;

const MultiPlayerRowConnected: React.FC<
  TMultiPlayerRowConnectedProps
> = props => {
  const { tabs = [], bottomLink, openVideoOnYoutube, hideTabs } = props;
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');
  const preparedTabs = useMemo(
    () =>
      tabs.map(tab => ({
        ...tab,
        url: tab.url?.[0] ? toLinkProps('', tab.url[0]) : undefined,
        videos: tab?.selectedVideos
          ? tab.selectedVideos
              .filter(
                (video): video is VideoCollection => typeof video !== 'string',
              )
              .map(video => {
                const { cover, ...rest } = video;
                return {
                  ...rest,
                  cover: toImageProps(cover),
                };
              })
          : undefined,
      })),
    [tabs],
  );
  const bottomLinkPrepared = bottomLink?.[0]
    ? {
        ...toLinkProps(currentLocale, bottomLink[0]),
        children: bottomLink[0].text,
      }
    : undefined;

  return (
    <MultiPlayer
      tabs={preparedTabs}
      bottomLink={bottomLinkPrepared}
      openVideoOnYoutube={openVideoOnYoutube as EOpenVideoOnYoutube}
      hideTabs={hideTabs}
    />
  );
};

export default MultiPlayerRowConnected;
